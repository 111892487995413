//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'PostNotify',
    props: ['data'],
    data() {
        return {
            message: JSON.parse(this.data.extra).message,
            post_id: JSON.parse(this.data.extra).post_id
        }
    }
}
