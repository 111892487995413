import { render, staticRenderFns } from "./EntityInvite.vue?vue&type=template&id=2add0d03&"
import script from "./EntityInvite.vue?vue&type=script&lang=js&"
export * from "./EntityInvite.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QItem,QItemSection,QAvatar,QBadge,QItemLabel,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QItem,QItemSection,QAvatar,QBadge,QItemLabel})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
