//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['data'],
    data() {
        return {
            name: JSON.parse(this.data.extra).name,
            id: JSON.parse(this.data.extra).id,
            profile_photo: (JSON.parse(this.data.extra).profile_photo != 'not_available') 
                ? JSON.parse(this.data.extra).profile_photo 
                : this.loadPhotoThumbnail()
        }
    },
    methods: {
        deleteNotification() {
            this.$emit('deleted', this.data.id);
        },
        gotoProfile() {
            this.$router.push({name: 'Profile', params: {id: this.id}});
        },
        replaceByDefault() {
            console.log('err');
            this.profile_photo = require('@/assets/default_profile.webp');
        },
        loadPhotoThumbnail(){
            this.payload = {
                user_id: JSON.parse(this.data.extra).id,
            }
            this.$store.dispatch('profile/getProfilePhoto', this.payload)
                .then((response) =>{
                    if(response.status == 'success'){
                        this.profile_photo = response.image
                    }
                })
        },
    }
}
