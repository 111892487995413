//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'EntityInvite',
    props: ['data'],
    data() {
        return {
            entity_name: JSON.parse(this.data.extra).entity_name,
            entity_type: JSON.parse(this.data.extra).entity_type,
            inviter_name: JSON.parse(this.data.extra).inviter_name,
            entity_id: JSON.parse(this.data.extra).entity_id
        }
    },
    methods: {
        onOpenInvite() {
            switch (this.entity_type) {
                case 'group':
                    this.$router.push({name: 'Group', params: {id: this.entity_id}})
                break;
                case 'event':
                    this.$router.push({name: 'Event', params: {id: this.entity_id}})
                break;
                case 'store':
                    this.$router.push({name: 'Store', params: {id: this.entity_id}})
                break;
            }
        }
    }
}
