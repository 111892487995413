//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PostReaction from '@/components/Notification/Templates/PostReaction'
import FriendRequest from '@/components/Notification/Templates/FriendRequest'
import CommentNotify from '@/components/Notification/Templates/CommentNotify'
import PostNotify from '@/components/Notification/Templates/PostNotify'
import EntityInvite from '@/components/Notification/Templates/EntityInvite'
export default {
    name:"NotificationItem",
    props: ['notification'],
    components:{
        PostReaction,
        FriendRequest,
        CommentNotify,
        EntityInvite,
        PostNotify
    },
    methods: {
        deleteNotification() {
            this.$emit('deleted', this.notification.id);
        }
    }
}
